.custom-radio {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div {
    display: flex;
    gap: 8px;
  }

  input[type="radio"] {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  label {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    cursor: pointer;
  }

  &__info {
    cursor: help;
    position: relative;
    img {
      width: 24px;
      &:hover + .custom-radio__info__text {
        display: flex;
      }
    }
    &__text {
      cursor: default;
      position: absolute;
      height: fit-content;
      background-color: var(--dark-gray);
      padding: 8px 16px;
      width: 200px;
      text-align: center;
      border-radius: 8px;
      box-shadow: 1px 1px 5px #000;
      bottom: calc(50% - 30px);
      left: 35px;
      display: none;

      &:hover {
        display: flex;
      }
    }
  }
}
