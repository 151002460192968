@import url("./chose-lang.scss");
@import url("./custom-button.scss");
@import url("./custom-input.scss");
@import url("./spinner.scss");
@import url("./custom-radio.scss");

.modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }
}
