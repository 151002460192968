@import "../mixin";
@import "../template";

.home__upper-footer {
  background-color: var(--black);
  color: var(--white);
  padding: 24px 0;
  &__content {
    @extend %home-content;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    & > div {
      color: var(--dark-gray);
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
  &__ask {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
  }

  &__actions {
    text-transform: uppercase;
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    gap: 4px;
    align-items: center;
  }

  @include lg {
    padding: 24px 0;
    &__content {
      padding: 0 20px;
      gap: 8px;

      & > div {
        gap: 4px;
      }
    }
    &__ask {
      font-size: 12px;
      line-height: 28px;
    }

    &__actions {
      font-size: 14px;
      line-height: 28px;
    }
  }

  @media (max-width: 900px) {
    &__content {
      padding: 0 10px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
    &__ask {
      font-size: 16px;
    }

    &__actions {
      font-size: 18px;
    }
  }

  @media (max-width: 630px) {
    &__offer,
    &__site {
      flex-direction: column;
      text-align: center;
    }
  }

  @include sm {
    padding: 2rem 0;

    &__content {
      gap: 2rem;

      & > div {
        gap: 0.8rem;
      }
    }

    &__offer,
    &__site {
      flex-direction: column;
      text-align: center;
    }

    &__ask {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }

    &__actions {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}

.russian {
  .home__upper-footer {
    @media (max-width: 960px) {
      &__content {
        padding: 0 10px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }
      &__ask {
        font-size: 16px;
      }

      &__actions {
        font-size: 18px;
      }
    }

    @include sm {
      &__content {
        padding: 0 1rem;
        gap: 2.4rem;
      }
      &__ask {
        font-size: 1.4rem;
      }

      &__actions {
        font-size: 1.2rem;
      }
    }
  }
}
