:root {
  --color: red;
  --main-color: rgb(0, 158, 16);
  --accent-color: rgb(97, 0, 102);
  --main-color: #9bc53d;
  --secondary-color: #ebfffa;
  --color-main: #333333;
  --color-alert: #ffecef;
  --color-primary: #7a9be3;
  --primary-color: #23d160;

  // color
  --white: #fff;
  --black: #000;
  --yellow: #ffa723;
  --red: #cf283a;
  --success: #0f0;
  --gray: #f6f6f6;

  --dark-gray: #7b7b7b;
  --dark-red: #410d12;

  --gray-1: #e2e2e2;

  // opacity
  --red-opacity-05: rgb(207, 40, 58, 0.5);
  --dark-blue-opacity-06: rgba(39, 44, 61, 0.6);

  // custom
  --black-title: #222328;
  --remark-text: #aaa;
  --light-ping: #e9d2d8;

  // hover
  --yellow-hover: #ffbb54;
  --red-hover: #dd3e4e;

  // components
  --bg-header: #f4f4f4;
  --bg-footer: #222328;

  //box
  --opacity-box-blue: rgba(39, 44, 61, 0.6);

  // dimensions
  --home-width-content: 1130px;
}
