@import "../mixin";

.login-modal {
  outline: none;
  position: absolute;
  width: 350px;
  background-color: var(--white);
  border-radius: 12px;
  left: calc(50vw - 175px);
  max-height: 90vh;
  overflow: auto;
  top: 5vh;
  padding: 60px 24px 20px;

  &__title {
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    text-align: center;
  }

  &__reset-pas {
    display: block;
    color: var(--red);
    font-size: 16px;
    line-height: 24px;
    width: fit-content;
    margin-left: auto;
    background-color: transparent;
    padding: 0 !important;
  }

  &__form {
    margin-top: 28px;
    @include columnGap(20px);

    input {
      min-width: 300px;
    }

    .login-modal__button {
      min-width: 300px;
    }

    button {
      font-size: 20px;
      font-weight: 500;
      line-height: 34px;
      padding: 8px 20px;
      border-radius: 8px;
    }
  }

  .reset-password {
    @include columnGap(28px);

    &__title {
      text-align: center;
      line-height: 120%;
    }

    &__content {
      @include columnGap(20px);
    }

    p {
      color: var(--dark-gray-2);
    }
  }

  @media (max-device-height: 550px) and (orientation: landscape) {
    top: 3vh;
    max-height: 85vh;
  }
}
