@import "../_template.scss";
@import "../mixin";

.home__broadcast {
  background-color: var(--bg-footer);
  padding: 100px 0;

  &__content {
    @extend %home-content;
    display: flex;
    gap: 124px;
    color: var(--white);
  }

  &__info {
    @include columnGap(52px);
    width: 100%;

    h1 {
      font-size: 52px;
      line-height: 52px;
      a {
        color: var(--yellow);
        &:hover {
          color: var(--yellow-hover);
        }
      }
    }
  }

  &__text {
    &__accent {
      color: var(--yellow);
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
    }

    &-hello {
      margin-left: 32px;
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      padding-bottom: 32px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }

    &__terms {
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }

    &__remark {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      color: var(--remark-text);
    }

    &-percent {
      margin-left: 32px;
      h2 {
        font-size: 40px;
        line-height: 100%;
      }
    }
  }

  &__terms {
    @include columnGap(52px);

    background-color: var(--red-opacity-05);
    border-radius: 12px;
    padding: 68px 32px;

    h3 {
      font-size: 40px;
      line-height: 100%;
    }

    .home__broadcast__text__remark {
      color: var(--light-ping);
    }

    &__text {
      font-size: 28px;
      font-weight: 700;
      line-height: 32px;
    }

    &__footer {
      .home__broadcast__text__remark {
        color: var(--white);
      }

      a {
        color: var(--yellow);
      }

      &__accent-mini {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 8px;
        color: var(--yellow);
        text-transform: uppercase;
      }
    }
  }

  &__register {
    max-width: 444px;
    width: 100%;
    background-color: var(--dark-blue-opacity-06);
    padding: 32px;
    border-radius: 12px;
    border: 4px solid var(--yellow);
    @include columnGap(16px);

    form {
      @include columnGap(16px);
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-size: 34px;
      line-height: 36px;
      text-align: center;
      display: flex;
      align-items: start;
      gap: 12px;

      img {
        width: 36px;
      }
    }

    &__subtitle {
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      a {
        color: var(--yellow);
      }
    }

    &__input.custom-input {
      img {
        top: 18px;
        left: 20px;
      }

      input {
        border: 2px solid var(--remark-text);
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 6px;
        color: var(--white);
        padding: 12px 20px;
        padding-left: 52px;

        &::placeholder {
          color: var(--remark-text);
        }
      }

      .custom-input__gray-warning {
        margin-top: 8px;
        color: var(--dark-gray);
        font-size: 18px;
        line-height: 110%;
      }
    }

    .custom-button {
      padding: 18px 0;
    }

    &__text {
      color: var(--dark-gray);
      font-size: 16px;
      line-height: 16px;
      @include columnGap(8px);

      button {
        background: transparent;
        padding: 0;
        color: var(--yellow);
        font-size: 18px;
      }
    }
  }

  @include lg {
    &__content {
      padding: 0 20px;
      gap: 48px;
      justify-content: space-between;
    }

    &__info {
      @include columnGap(24px);
      max-width: 444px;
      justify-content: space-around;

      &.is-confirm {
        max-width: 100%;
      }

      h1 {
        font-size: 36px;
        line-height: 36px;
      }
    }

    &__text {
      &__accent {
        font-size: 20px;
        line-height: 24px;
      }

      &-hello {
        margin-left: 24px;
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 20px;
      }

      &__terms {
        font-size: 20px;
        line-height: 24px;
      }

      &__remark {
        font-size: 16px;
        line-height: 16px;
      }

      &-percent {
        margin-left: 24px;

        h2 {
          font-size: 32px;
        }
      }
    }

    &__terms {
      @include columnGap(36px);

      h3 {
        font-size: 32px;
      }
      border-radius: 8px;
      padding: 32px 24px;

      &__text {
        font-size: 20px;
        line-height: 24px;
      }

      &__footer {
        &__accent-mini {
          font-size: 16px;
          line-height: 20px;
          margin-top: 6px;
        }
      }
    }

    &__register {
      max-width: 352px;

      padding: 20px;
      border-radius: 8px;
      @include columnGap(12px);

      h2 {
        font-size: 28px;
        line-height: 28px;

        gap: 8px;

        img {
          width: 24px;
        }
      }

      &__subtitle {
        font-size: 16px;
        line-height: 24px;
      }

      .custom-button {
        padding: 14px 0;
      }

      &__text {
        font-size: 12px;
        line-height: 14px;
        @include columnGap(6px);

        button {
          font-size: 16px;
        }
      }
    }
  }

  @include lsm {
    padding: 52px 0;

    &__content {
      padding: 0px 10px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    &__info {
      max-width: 444px;
    }
  }

  @include sm {
    padding: 5rem 0;

    &__content {
      gap: 4.2rem;
    }

    &__info {
      @include columnGap(32px);
      width: 100%;
      max-width: 100%;

      h1 {
        font-size: 3.6rem;
        line-height: 4rem;
      }
    }

    &__text {
      &__accent {
        font-size: 2.8rem;
        line-height: 3.2rem;
      }

      &-hello {
        margin-left: 2.4rem;
        font-size: 2.2rem;
        line-height: 3.2rem;
        padding-bottom: 2.8rem;
      }

      &__terms {
        font-size: 2.2rem;
        line-height: 3.2rem;
      }

      &__remark {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      &-percent {
        margin-left: 2.4rem;
        h2 {
          font-size: 2.8rem;
        }
      }
    }

    &__terms {
      @include columnGap(3.6rem);

      h3 {
        font-size: 2.6rem;
      }
      border-radius: 0.8rem;
      padding: 3.6rem 2.4rem;

      &__text {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      &__footer {
        &__accent-mini {
          font-size: 1.8rem;
          line-height: 2.4rem;
          margin-top: 0.8rem;
        }
      }
    }

    &__register {
      max-width: 44.4rem;
      width: 100%;
      padding: 1.6rem 1.2rem;
      border-radius: 0.8rem;
      @include columnGap(1.6rem);

      h2 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        gap: 0.8rem;

        img {
          width: 2.4rem;
        }
      }

      &__subtitle {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }

      .custom-button {
        padding: 1.6rem 0;
      }

      &__text {
        font-size: 1.4rem;
        line-height: 1.6rem;
        @include columnGap(8px);

        button {
          background: transparent;
          padding: 0;
          color: var(--yellow);
          font-size: 18px;
        }
      }
    }

    @media (max-width: 385px) {
      .recaptche-container {
        position: relative;
        left: -0.5rem;
      }
    }

    @media (max-width: 370px) {
      .recaptche-container {
        position: relative;
        left: -1rem;

        width: calc(100% + 2rem);
        overflow: hidden;
      }
    }
  }
}

.russian {
  .home__broadcast {
    &__register h2 {
      font-size: 28px;
    }

    &__register__text__terms {
      font-size: 14px;
      button {
        font-size: 14.5px;
      }
    }

    &__text {
      &__remark {
        font-size: 16px;
      }
    }

    @media (max-width: 1150px) {
      .custom-radio__info__text {
        left: auto;
        right: -100px;
        bottom: 36px;
        font-size: 15px;
      }
    }

    @include lg {
      &__register h2 {
        font-size: 24px;
      }

      &__register__text__terms {
        font-size: 12px;
        button {
          font-size: 16px;
        }
      }

      &__text {
        &__remark {
          font-size: 16px;
        }
      }
    }

    @include sm {
      &__text-hello {
        font-size: 1.7rem;
        line-height: 2rem;
      }

      &__register h2 {
        font-size: 2rem;
      }

      &__register__text__terms {
        font-size: 1.4rem;
        button {
          font-size: 1.2rem;
        }
      }

      &__text {
        &__terms {
          font-size: 2rem;

          .home__broadcast__text__accent {
            font-size: 2.4rem;
          }
        }

        &__remark {
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
      }

      &__terms__footer__accent-mini {
        font-size: 1.6rem;
      }
    }
  }
}
