@import "../../../style/mixin";

.form-modal {
  h1 {
    text-align: center;
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    margin-top: 20px;
  }

  .required {
    color: var(--red);
  }

  form {
    margin-top: 20px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__two {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__content {
      display: flex;
      gap: 12px;

      & > div {
        width: 100%;
      }
    }
  }

  &__fieldset__container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__fieldset {
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > div {
      display: flex;
      align-items: center;
      font-size: 16px;
      label {
        cursor: pointer;
        padding-left: 16px;
      }
    }
  }

  &__button {
    margin-top: 20px;
  }

  @include lsm {
    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 16px;
    }

    &__two__content {
      flex-direction: column;
    }
  }
}
