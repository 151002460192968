@import "../_template.scss";
@import "../mixin";

.home__income {
  background-color: var(--dark-red);
  padding: 32px 0;
  &__content {
    @extend %home-content;
    display: flex;
    justify-content: space-around;
  }

  &__card {
    padding: 28px 48px;
    width: 432px;
    @include columnGap(12px);
    align-items: center;
    border: 4px solid var(--yellow);
    border-radius: 12px;

    &__title {
      font-size: 28px;
      line-height: 34px;
      font-weight: 500;
      color: var(--remark-text);
      text-align: center;

      &__accent {
        font-size: 32px;
        color: var(--yellow);
        font-weight: 700;
        text-transform: uppercase;
      }

      a {
        font-size: 32px;
        font-weight: 700;
        color: var(--white);
        text-decoration: underline;
      }
    }

    &__value {
      margin-top: 16px;
      font-size: 52px;
      font-weight: 700;
      line-height: 34px;
      color: var(--yellow);
    }

    &__remark {
      color: var(--remark-text);
      font-size: 18px;
      line-height: 34px;
    }
  }

  @include lg {
    &__content {
      padding: 0 20px;
    }

    &__card {
      padding: 12px;
      width: 40%;
      max-width: 400px;
      @include columnGap(8px);
      border-radius: 8px;

      &__title {
        font-size: 22px;
        line-height: 28px;
        font-weight: 500;

        &__accent {
          font-size: 26px;
        }

        a {
          font-size: 26px;
        }
      }

      &__value {
        margin-top: 12px;
        font-size: 40px;
        line-height: 24px;
      }

      &__remark {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 770px) {
    &__card {
      &__title {
        font-size: 20px;
      }
    }
  }

  @include lsm {
    padding: 52px 0;

    &__content {
      padding: 0 10px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
  }

  @include lxs {
    &__card {
      width: 90%;
      padding: 28px 12px;
    }
  }

  @include sm {
    padding: 3.2rem 0;

    &__card {
      padding: 2.4rem 3.6rem;
      width: 28rem;
      @include columnGap(1rem);
      border-radius: 0.8rem;

      &__title {
        font-size: 2.2rem;
        line-height: 2.8rem;

        &__accent {
          font-size: 2.4rem;
        }

        a {
          font-size: 2.4rem;
        }
      }

      &__value {
        margin-top: 1.2rem;
        font-size: 3.6rem;
        line-height: 2rem;
      }

      &__remark {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }
}

.russian {
  .home__income {
    @include sm {
      &__card__title__accent {
        font-size: 3.2rem;
        padding: 0 2.5rem;
      }
    }
  }
}
