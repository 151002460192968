%opacity-blue-box {
  background-color: var(--opacity-box-blue);
  padding: 32px;
  border-radius: 12px;
}

%home-content {
  max-width: var(--home-width-content);
  margin: 0 auto;

  @media (max-device-width: 739px) {
    padding: 0 2rem;
  }
}
