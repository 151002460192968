@import "../_mixin.scss";

.custom-input {
  position: relative;
  display: flex;
  flex-direction: column;

  &__gray-warning {
    font-size: 16px;
    line-height: 24px;
    color: var(--gray-text);
  }

  input,
  textarea {
    font-size: 18px;
    line-height: 34px;
    padding-left: 40px;

    &:focus {
      outline: none;
    }
  }

  .no-resize {
    resize: none;
  }

  .password__hide__btn {
    position: absolute;
    left: auto;
    right: 20px;
    top: 2px;
    width: 20px;
    padding: 0;
  }

  img {
    position: absolute;
    left: 8px;
    top: 6px;
    width: 24px;
  }
}
