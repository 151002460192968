@import "../_template.scss";
@import "../mixin";

.home__advantages {
  background-color: var(--white);
  padding: 100px 0;

  &__content {
    @extend %home-content;
    @include columnGap(24px);
  }

  &__title {
    font-size: 40px;
    line-height: 52px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    text-align: center;

    a {
      color: var(--yellow);
      text-transform: none;
      font-size: 48px;
    }
  }

  &__info {
    display: flex;

    h3 {
      font-size: 24px;
      line-height: 125%;
      text-transform: uppercase;
    }

    &__list {
      width: calc(50%);
      position: relative;
      z-index: 1;
      ul {
        @include columnGap(24px);
        margin-top: 32px;
      }

      &__li {
        &__title {
          display: flex;
          gap: 12px;
          align-items: start;
          font-size: 20px;
          line-height: 120%;

          h4 {
            font-weight: 500;
          }
        }

        &__description {
          margin-left: 36px;
          display: inline-block;
          color: var(--dark-gray);
          font-size: 16px;
          line-height: 110%;
        }
      }
    }

    &__img {
      position: relative;
      width: calc(50% + 100px);
      img {
        position: absolute;
        left: 0;
        bottom: -100px;
        z-index: 0;
      }
    }
  }

  @include lg {
    overflow: hidden;

    &__content {
      padding: 0 20px;
      @include columnGap(20px);
    }

    &__title {
      font-size: 36px;
      line-height: 48px;

      a {
        font-size: 44px;
      }
    }

    &__info {
      &__list {
        width: 60%;
      }
      h3 {
        font-size: 20px;
      }

      &__img {
        position: relative;
        width: 50%;
        img {
          position: absolute;
          left: -120px;
          bottom: -100px;
          z-index: 0;
        }
      }
    }
  }

  @include lsm {
    padding: 52px 0;
    padding-bottom: 0;

    &__content {
      padding: 0 10px;
    }

    &__info {
      flex-direction: column;
      align-items: center;

      & > div {
        width: 100%;
      }

      &__img {
        img {
          position: relative;
          bottom: -6px;
        }
      }
    }
  }

  @include lxs {
    &__info__img {
      left: -130px;
    }
  }

  @include sm {
    padding: 5.2rem 0;
    overflow: hidden;

    &__content {
      @include columnGap(2rem);
    }

    &__title {
      font-size: 2.8rem;
      line-height: 3.6rem;

      a {
        font-size: 3.6rem;
      }
    }

    &__info {
      h3 {
        font-size: 2rem;
        line-height: 125%;
      }

      img {
        width: 2rem;
        height: 2rem;
      }

      &__list {
        width: 100%;

        ul {
          @include columnGap(1.6rem);
          margin-top: 1rem;
        }

        &__li {
          &__title {
            gap: 0.8rem;
            font-size: 1.6rem;
          }

          &__description {
            margin-left: 2.8rem;
            font-size: 1.2rem;
          }
        }
      }

      &__img {
        width: 100%;
        left: 0;
        height: 100%;
        img {
          left: -50px;
          position: relative;
          bottom: -5.6rem;
          width: auto;
          height: 100%;

          @media (max-width: 530px) {
            left: -130px;
          }
          @media (max-width: 330px) {
            left: -220px;
          }
        }
      }
    }
  }
}

.russian {
  .home__advantages {
    &__info {
      h3 {
        font-size: 18px;
      }

      h4 {
        font-size: 1.5rem;
      }
    }

    @include sm {
      &__info h3 {
        font-size: 1.8rem;
      }
    }
  }
}
