@import "../template.scss";
@import "../mixin";

.header {
  background-color: var(--bg-header);
  width: 100vw;
  max-width: 100%;
  padding: 24px 0;

  &__content {
    @extend %home-content;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: var(--black-title);
    font-weight: 600;
    letter-spacing: 0.64px;
    font-size: 32px;
    line-height: 36px;
  }

  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    color: var(--dark-gray);

    a {
      color: var(--red);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 32px;

    &__buttons {
      gap: 24px;
      display: flex;
    }
  }

  @include lg {
    &__content {
      padding: 0 20px;
    }

    &__actions {
      gap: 12px;

      &__buttons {
        gap: 10px;
      }
      .custom-button {
        padding: 12px 24px;
        img {
          display: none;
        }
      }

      .chose-lang {
        margin-left: auto;
      }
    }
  }
  @media (max-width: 890px) {
    gap: 8px;

    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 16px;
    }

    .custom-button {
      padding: 12px;
      span {
        width: 100%;
        text-align: center;
      }

      &__main-text {
        font-size: 18px;
      }

      &__sub-text {
        font-size: 12px;
      }
    }
  }

  @include lsm {
    &__content {
      padding: 0 10px;
      flex-direction: column-reverse;
      gap: 32px;
    }

    &__actions {
      gap: 16px;
      width: 100%;
      justify-content: space-between;
    }

    &__title {
      font-size: 32px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }

  @include lxs {
    &__content {
      align-items: start;
      position: relative;
    }

    &__actions {
      &__buttons {
        flex-direction: column;
        margin: auto;
        width: 100%;
        max-width: 400px;
        .custom-button {
          width: 100%;
        }
      }

      .chose-lang {
        position: absolute;
        bottom: 0;
        right: 10px;
      }
    }
  }

  @include sm {
    &__actions {
      &__buttons {
        flex-direction: column;
        margin: auto;
        width: 100%;
        max-width: 100%;
        .custom-button {
          width: 100%;
        }
      }

      .chose-lang {
        position: absolute;
        bottom: 0;
        right: 2rem;
      }
    }

    &__content {
      align-items: start;
      position: relative;
      padding-top: 6rem !important;
    }

    .custom-button {
      &__main-text {
        font-size: 1.8rem;
      }

      &__sub-text {
        font-size: 1.2rem;
      }
    }

    &__title {
      font-size: 3rem;
      line-height: 3.6rem;
      text-align: center;
      img {
        height: 3rem;
      }
    }

    &__text {
      margin: auto;
    }

    &__subtitle {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    .chose-lang {
      right: 2rem;
      bottom: auto;
      top: 0;
      padding: 0.8rem 1.2rem;
    }
  }
}

.russian {
  .header {
    @include lg {
      &__title {
        font-size: 28px;
        svg {
          height: 18px;
        }
      }

      &__subtitle {
        font-size: 12px;
      }
    }

    .custom-button {
      .custom-button__sub-text {
        font-size: 12px;
        line-height: 14px;
      }
    }

    @include sm {
      .custom-button {
        .custom-button__sub-text {
          font-size: 1.1rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}
